import { State } from "../state";
import { Request } from "@/network/request";
import i18n from "../../i18n";
import toast from "../../plugins/toast";

export default class exercise {
  types = [
    { value: "video", text: i18n.t("video") },
    { value: "presentation", text: i18n.t("presentation") },
    { value: "voice_record", text: i18n.t("voice_record") },
    { value: "cross_words", text: i18n.t("cross_words") },
    { value: "choose", text: i18n.t("choose") },
    { value: "puzzle", text: i18n.t("puzzle") },
    { value: "match", text: i18n.t("match") },
    { value: "true_or_false", text: i18n.t("true_or_false") },
    { value: "reorder", text: i18n.t("reorder") },
    { value: "coloring", text: i18n.t("coloring") },
    { value: "maze", text: i18n.t("maze") },
    { value: "memory", text: i18n.t("memory") },
    { value: "categorize", text: i18n.t("categorize") },
    { value: "fill_gaps", text: i18n.t("fill_gaps") },
  ];
  tableData = {
    data: [],
    examData: [],

    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
    examDataMeta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };

  getState = new State();
  getByExamIdState = new State();

  get(page) {
    let request = new Request({
      method: "get",
      endpoint: `/teacher/examExercise`,
      params: page,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }

  getByExamId(params) {
    let request = new Request({
      method: "get",
      endpoint: `/teacher/examExercise`,
      params: params,
      token: this.token,
    });

    this.getByExamIdState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.examData = value.data;
          this.tableData.examDataMeta = value.meta;
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }

  clearData() {
    (this.exercise.name = null),
      (this.exercise.serial = null),
      (this.exercise.description = null),
      (this.exercise.age_group_id = null),
      (this.exercise.individual_differences = null),
      (this.exercise.type = null),
      (this.exercise.skills = []);
  }
}
export const Exercise = new exercise();
