<template>
  <div class="d-block" v-if="!loader">
    <v-row class="mt-n16 mb-8 justify-center">
      <v-col class="d-flex justify-center text--primary" lg="8" offset-lg="2">
        <h1 class="primary--text">{{ data.name }}</h1>
      </v-col>
      <v-col lg="2" class="d-flex justify-end align-center"
        ><v-btn
          v-if="examStatus == 'upcoming'"
          @click="activateExam"
          color="primary"
          elevation="2"
          rounded
          :loading="activeLoader"
          >{{ $t("activate") }}</v-btn
        >
        <v-btn
          v-if="examStatus == 'active'"
          color="red white--text"
          @click="deactivateExam"
          rounded
          class="w-100 mx-2"
          :loading="activeLoader"
          >{{ $t("de active") }}</v-btn
        >
        <v-btn
          v-if="examStatus == 'active'"
          color="white primary--text"
          rounded
          class="w-100"
          >{{ $t("active") }}</v-btn
        >

        <v-btn
          v-if="examStatus == 'finished'"
          color="red"
          class="white--text"
          rounded
          >{{ $t("finished") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="justify-space-around align-center my-6 text-center">
      <v-col lg="3">
        <p>
          {{ $t("grade to pass") }}
        </p>
        <h2 class="primary--text">{{ data.grade_to_pass }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("starting date") }}</p>
        <h2 class="primary--text">{{ data.starting_date }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("ending date") }}</p>
        <h2 class="primary--text">{{ data.finishing_date }}</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs grow icons-and-text>
      <v-tab> {{ $t("exercises") }} </v-tab>
      <v-tab> {{ $t("students") }} </v-tab>

      <v-tab-item>
        <v-divider></v-divider>
        <v-row class="mt-6">
          <v-col lg="8">
            <v-row class="px-4 justify-center"
              ><table-filter
                @getData="getData($event)"
                @clearData="clearFilter"
                :types="types"
                :date="true"
            /></v-row>
            <selected
              v-if="notSelectedItems.length > 0"
              @submit="attachItems($event)"
              @updatePage="getData({ page: $event })"
              :items="notSelectedItems"
              :headers="headers"
              :meta="notSelectedMeta"
              :loader="notSelectedLoader"
              :state="notSelectedState"
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="4">
            <selected
              v-if="selectedItems.length > 0"
              @submit="deAttachItems($event)"
              @updatePage="getSelectedData({ page: $event })"
              :items="selectedItems"
              :headers="headers"
              :meta="selectedMeta"
              :loader="selectedLoader"
              :state="selectedState"
              :label="'exercise in this exam'"
            />
            <div class="primary--text pa-3 d-flex flex-column justify-center">
              <p class=" font-weight-800 text-center">
                {{ $t("No Exercises in This Exam") }}
              </p>
              <v-icon color="primary " size="40"
                >mdi-alert-circle-outline</v-icon
              >
            </div>
          </v-col>
          <v-dialog
            v-if="Exams.postState.loading"
            v-model="dialog"
            hide-overlay
            persistent
            width="300"
            style="z-index: 99;"
          >
            <v-card color="primary" dark>
              <span class="ma-2"> {{ $t("adding") }}</span>
              <v-card-text>
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-divider></v-divider>
        <data-table
          @updatePage="Student.get({ page: $event })"
          :headers="Student.tableData.headers"
          :data="Student.tableData.data"
          :loader="Student.getState.loading"
          :meta="Student.tableData.meta"
          :showable="
            (obj) => {
              $router.push(`/student_exams/${obj.id}`);
            }
          "
        >
        </data-table>
      </v-tab-item>
    </v-tabs>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { Exercise } from "@/store/teacher/exercise";
import { Exams } from "@/store/teacher/exams";
import { Users as Student } from "@/store/teacher/students/index";
import selected from "./selected.vue";
import tableFilter from "../../Dashboard/Exercise/exerciseFilter.vue";

export default {
  components: {
    selected,
    tableFilter,
  },
  data() {
    return {
      Exercise,
      Exams,
      Student,
      dialog: true,
      examStatus: "upcoming",
      filterData: {},
      notSelectedState: false,
      selectedState: false,
      headers: {
        id: {
          name: "id",
          keys: ["serial"],
        },

        name: {
          name: "name",
          keys: ["name"],
        },

        type: {
          name: "type",
          keys: ["type"],
        },
        type: {
          name: "type",
          keys: ["type"],
        },
        select: {
          slot: "select",
        },
      },
    };
  },
  methods: {
    getData(data) {
      Object.assign(data, { exclude_exam_id: this.id });
      Object.assign(this.filterData, data);
      Exercise.get(this.filterData);
    },
    getSelectedData(data) {
      Object.assign(data, { exam_id: this.id });
      this.filterData = data;
      Exercise.getByExamId(data);
      this.filterData = {};
    },
    async attachItems(data) {
      if (Exams.postState.loading) return;
      await Exams.addExercise(
        this.id,
        { exercises_ids: data },
        {
          onSuccess: () => {
            this.notSelectedState = true;
            this.getData({ page: 1 });
            this.getSelectedData({ page: 1 });
          },
        }
      );
      this.notSelectedState = false;
    },
    async deAttachItems(data) {
      if (Exams.postState.loading) return;
      await Exams.deleteExercises(
        this.id,
        { exercises_ids: data },
        {
          onSuccess: () => {
            this.selectedState = true;
            this.getData({ page: 1 });
            this.getSelectedData({ page: 1 });
          },
        }
      );
      this.selectedState = false;
    },
    async activateExam() {
      await Exams.edit({ status: "active" }, this.id, {
        onSuccess: () => {
          this.examStatus = "active";
          Exams.show(this.id);
        },
      });
    },
    async deactivateExam() {
      await Exams.edit({ status: "finished" }, this.id, {
        onSuccess: () => {
          this.examStatus = "upcoming";
          Exams.show(this.id);
        },
      });
    },
    clearFilter() {
      this.filterData = {};
      this.getData({ page: 1 });
    },
  },
  computed: {
    data() {
      return Exams.tableData.showData;
    },
    loader() {
      return Exams.showState.loading;
    },
    activeLoader() {
      return Exams.editState.loading;
    },
    selectedItems() {
      return Exercise.tableData.examData;
    },
    selectedMeta() {
      return Exercise.tableData.examDataMeta;
    },
    selectedLoader() {
      return Exercise.getByExamIdState.loading;
    },

    notSelectedItems() {
      return Exercise.tableData.data;
    },
    notSelectedMeta() {
      return Exercise.tableData.meta;
    },
    notSelectedLoader() {
      return Exercise.getState.loading;
    },
    types() {
      return Exercise.types;
    },

    id() {
      return this.$route.params.id;
    },
  },
  async created() {
    await Exams.show(this.id);
    this.getData({ page: 1 });
    this.getSelectedData({ page: 1 });
    Student.get({ exam_id: this.id });
    this.examStatus = this.data.status;
  },
};
</script>
<style lang="scss" scoped>
p {
  font-weight: 600;
}
</style>
