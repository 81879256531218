import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class exams {
  endPoint = "teacher/exams";
  selected = [];
  tableData = {
    data: [],
    showData: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },
      starting_date: {
        name: "starting_date",
        keys: ["starting_date"],
      },
      finishing_date: {
        name: "finishing_date",
        keys: ["finishing_date"],
      },
      edu_class: {
        name: "edu class",
        keys: ["edu_class", "name"],
      },
      subject: {
        name: "subject",
        keys: ["subject", "name"],
      },
      grade_to_pass: {
        name: "grade_to_pass",
        keys: ["grade_to_pass"],
      },
      students_count: {
        name: "student_count",
        keys: ["students_count"],
      },

      status: {
        slot: "status",
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  showState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();
  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `teacher/exams/index`,
      params: params,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.data;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async show(id) {
    let request = new Request({
      method: "get",
      endpoint: `/teacher/exam/show/${id}`,
      token: this.token,
    });

    await this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/teacher/exam/add`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async edit(payload, id, { onSuccess }) {
    let request = new Request({
      method: "put",
      endpoint: `/teacher/exam/${id}`,
      data: payload,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(i18n.t("edit success"), { timeout: 4000, rtl: true }, "sucess");
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async deleteItem(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/teacher/exam/${id}`,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
  async addExercise(id, payload, { onSuccess }) {
    this.selected = payload;
    let request = new Request({
      method: "put",
      endpoint: `teacher/exam/attach/${id}`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          toast(
            i18n.t("exercise is already add to this lessson"),
            { timeout: 4000, rtl: true },
            "error"
          );
          this.selected = payload;
        },
      }
    );
  }
  async deleteExercises(id, payload, { onSuccess }) {
    this.selected = payload;
    let request = new Request({
      method: "put",
      endpoint: `/teacher/exam/detach/${id}`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          console.log(error);
          this.selected = payload;
        },
      }
    );
  }
}

export const Exams = new exams();
